import React, { useState } from "react";
import VehicleRedirect from "./VehicleRedirect";
import { overallStatus, formatNavTitle, capitalize } from "../../utils";
import VehicleSidebar from "./VehicleSidebar";
import _ from "lodash";
import VehicleDetails from "./VehicleDetails";
import { inject, observer } from "mobx-react";
import VehicleDocuments from "./VehicleDocuments";
import DamageReport from "../DamageReport";
import ServiceEvents from "../ServiceEvents";
import VehicleService from "../Vehicle/VehicleService";
import InformationTable from "../InformationTable";
import styles from "./table.module.scss";
import VehicleDamages from "./VehicleDamages";

const VehicleNew = observer(
  inject("store")(({ data, refetch, store, history, resource, resourceId }) => {
    const vehicleInfo = data.getVehicle;
    const tabs = [
      { name: "details" },
      { name: "documents" },
      { name: "tasks" },
      { name: "damages" },
      { name: "incidents" },
      { name: "inspection" },
    ];
    const [currentTab, setCurrentTab] = useState(resource || "details");
    const [docTab, setDocTab] = useState();
    const [mode, setMode] = useState("upcoming");

    const triggerTab = (childData) => {
      window.history.replaceState(
        "",
        "",
        `/vehicles/${vehicleInfo.id}/${childData}`
      );
      setCurrentTab(childData);
    };

    const changeMode = (mode) => {
      setMode(mode);
    };

    const {
      operationStatus,
      teslaVehicleStatus,
      inspectionStatus,
      serviceStatus,
      sentryModeStatus,
      valetModeStatus,
    } = vehicleInfo.statuses;

    const city = vehicleInfo.parkingLot.city;
    const documents = vehicleInfo.documents.slice().sort((a, b) => {
      return a.uploadedAt - b.uploadedAt;
    });
    const showLocation = (vehicle) => {
      store.changeSelectedVehicle(vehicle);
    };

    const currentTabNav = (page) => {
      if (page === docTab) {
        setDocTab(page);
      }

      if (page !== docTab) {
        setCurrentTab(page);
        setDocTab("");
        store.changeNavigatedPage(
          formatNavTitle(
            vehicleInfo,
            history.location.pathname + `/${currentTab}`,
            currentTabNav
          )
        );
      }
    };

    return (
      <div className="vehiclePage">
        <div className="vehicleRedirects">
          <VehicleRedirect plate={vehicleInfo.plate} tab={capitalize(currentTab)} />
        </div>
        <div className="vehicleContainer">
          <VehicleSidebar
            vehicleInfo={vehicleInfo}
            operationStatus={operationStatus}
            tabs={tabs}
            selectedTab={currentTab}
            triggerTab={triggerTab}
          />
          {currentTab === "details" && (
            <VehicleDetails
              vehicleInfo={vehicleInfo}
              showLocation={showLocation}
              city={city}
              operationStatus={operationStatus}
              teslaVehicleStatus={teslaVehicleStatus}
              serviceStatus={serviceStatus}
              inspectionStatus={inspectionStatus}
              sentryModeStatus={sentryModeStatus}
              valetModeStatus={valetModeStatus}
              refetch={refetch}
              currentUser={data.currentUser}
              vehicleTimeZone={vehicleInfo?.city?.timeZone}
            />
          )}
          {currentTab === "documents" && (
            <div>
              <VehicleDocuments
                vehicleId={vehicleInfo.id}
                vehicle={vehicleInfo}
                documents={documents}
                refetch={refetch}
                history={history}
                store={store}
                tab={currentTab}
                setDocTab={setDocTab}
                docTab={docTab}
                tabNav={currentTabNav}
              />
            </div>
          )}
          {currentTab === "tasks" && (
            <div className={styles.vehiclePage}>
              <InformationTable
                tableName={"tasks"}
                hasTabs={true}
                editable={true}
                background={"grey"}
                rowComponentType={"task"}
                startTerms={"Search"}
                item={"tasks"}
                id={vehicleInfo?.id}
                vehicleSpecific={true}
              />
            </div>
          )}
          {currentTab === "incidents" && (
            <div>
              <DamageReport
                id={vehicleInfo.id}
                plate={vehicleInfo.plate}
                make={vehicleInfo.make}
                damageReportView={vehicleInfo.damageReportView}
                history={history}
                interiorDamage={vehicleInfo.interiorDamageReports}
                exteriorDamage={vehicleInfo.exteriorDamageReports}
                refetch={refetch}
                tab={currentTab}
              />
            </div>
          )}
          {currentTab === "damages" && (
            <div>
              <VehicleDamages
                vehicleId={vehicleInfo?.id}
                resourceId={resourceId}
              />
            </div>
          )}
          {currentTab === "inspection" && (
            <div>
              <ServiceEvents
                fleetManager={vehicleInfo.currentUser}
                data={vehicleInfo}
                mode={mode}
                changeMode={changeMode}
                refetch={refetch}
                history={history}
              />
            </div>
          )}
        </div>
      </div>
    );
  })
);

export default VehicleNew;
