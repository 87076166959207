import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../locationSelect.module.scss";
import classNames from "classnames";
import { faParkingCircle } from "@fortawesome/pro-solid-svg-icons";
import { Checkbox } from "@material-ui/core";


const ParkingLot = ({ element, lotsSelected, selectLots }) => {

  const isSelected = (lot) => {return lotsSelected.some(parkinglot => parkinglot.name === lot.name)}

  return (
    <div>
      {" "}
      {element.map(lot => (
        <div
          className={styles.parkingLot}
          key={lot.id}
        >
          {" "}
            <Checkbox 
              style={{
                color:"#128dff"
              }}
              onClick={() => selectLots(lot)}
              checked={isSelected(lot)}/>
              {lot.name}
        </div>
      ))}
    </div>
  );
};

export default ParkingLot;
