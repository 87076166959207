import React, { useState } from "react";
import styles from "../locationSelect.module.scss";
import classNames from "classnames";
import _ from "lodash";
import ParkingLot from "./DropdownParkingLots";
import { Checkbox } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

const City = ({
  element,
  city,
  selectCities,
  selectLots,
  isCitySelected,
  bothSelected,
  lotsSelected,
}) => {

  const [showLots,setShowLots] = useState(false);
  const toggleLots = () => {setShowLots(!showLots)}

  const Chevron = () => {
    return (
    showLots?
      <FontAwesomeIcon icon={faChevronUp} color={"#dedede"}/>:
      <FontAwesomeIcon icon={faChevronDown} color={"#dedede"}/>
    )
  }

  return (
          <div className={styles.textContainer} key={city}>
            <div
              className={classNames(
                styles.city,
                styles.hover
              )}
              onClick={toggleLots}
            >
              <Checkbox 
              style={{
                color:"#128dff"
              }}
              onClick={(evt) => {
                selectCities(city)
                evt.stopPropagation()
              }} 
              checked={isCitySelected(city)}/>
              <div className={styles.displayName}>{_.startCase(city)} </div>
              <Chevron/>
            </div>
            {showLots &&
              <ParkingLot
                element={element}
                lotsSelected={lotsSelected}
                selectLots={selectLots}
              />
            }
          </div>
        );
};

export default City;
