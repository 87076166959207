import React from "react";
import styles from "../locationSelect.module.scss";
import _ from "lodash";
import LocationSelectButtons from "./Buttons";
import City from "./DropdownCity";

const LocationSelectDropdown = ({
  lots,
  lotsSelected,
  selectCities,
  selectLots,
  reset,
  store,
  toggleOpen
}) => {
  var cities = Object.keys(lots);
  const lotsCount = cities.length;
  let lotsList = [];
  for (let i = 0; i < lotsCount; i++) {
    lotsList = _.concat(lotsList, lots[cities[i]]);
  }
  const fullLots = lotsList.length;
  const bothSelected = lotsSelected.length === fullLots;

  const isCitySelected = key => {
    const cityIdList = [];
    lots[key].map(lot => cityIdList.push(lot.id));
    const lotsIdList = [];
    lotsSelected.map(lot => lotsIdList.push(lot.id));
    return cityIdList.every(id => lotsIdList.some(i => i === id));
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownContent}>
        {_.map(lots, (element, key) => { 
          return(
            <City
              element={element}
              city={key}
              selectCities={selectCities}
              selectLots={selectLots}
              isCitySelected={isCitySelected}
              bothSelected={bothSelected}
              lotsSelected={lotsSelected}
            />
        )})}
      </div>
      <LocationSelectButtons
        lotsSelected={lotsSelected}
        reset={reset}
        store={store}
        toggleOpen={toggleOpen}
      />
    </div>
  );
};

export default LocationSelectDropdown;
