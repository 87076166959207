import React from "react";
import styles from "../locationSelect.module.scss";
import Button from "../../../artzu/src/Button";

const LocationSelectButtons = ({ lotsSelected, reset, store, toggleOpen }) => {
  return (
    <div className={styles.buttons}>
      <div className={styles.reset} onClick={reset}>
        <Button
          label={"Reset"}
          height="32px"
          fullWidth="91px"
          color={"#128dff"}
          backColor="#ffffff"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #414141"
          onClick={() => reset()}
        />
      </div>
      <div className={styles.save}>
        <Button
          label={"Apply"}
          height="32px"
          fullWidth="91px"
          color={"#ffffff"}
          backColor="#128dff"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #FFFFFF"
          onClick={() => {
            localStorage.setItem("parkingLots", JSON.stringify(lotsSelected));
            store.changeSelectedLots(lotsSelected);
            toggleOpen();
          }}
        />
      </div>
    </div>
  );
};

export default LocationSelectButtons;
