import React, { Component } from "react";
import classNames from "classnames";
import styles from "./sidebar.module.scss";
import PlateStatus from "../../Fleet/PlateStatus";

const VehicleTitle = ({ make, model, year, plate, operationStatus }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleBox}>
        <div className={styles.smallDetails}>{year}</div>
        <div className={styles.largeDetails}>
          {make} {model}
        </div>
      </div>
      <div className={styles.titleBox}>
        <div className={styles.smallDetails}>License Plate</div>
        <div className={styles.largeDetails}>{plate}</div>
      </div>
      <div className={styles.titleBox}>
        <div className={styles.smallDetails}>Status</div>
        <PlateStatus
          operationStatus={operationStatus}
          display="details"
          title={true}
        />
      </div>
    </div>
  );
};

export default VehicleTitle;
